<template>
    <div>
        <template v-if="idTypeAndRelationDisagree">
            <div
                v-for="item of validItems"
                :key="item.type"
                class="flex item"
                :class="{ active: item.type === relation }"
                @click="setActive(item.type)"
            >
                {{ item.label }}
            </div>
        </template>

        <template v-else>
            <div
                v-for="(item, index) of validItems"
                :key="item.type"
                class="flex item"
                :class="{ active: index === currentIndex }"
                @click="setType(item.type, index)"
            >
                {{ item.label }}
            </div>
        </template>
    </div>
</template>

<script>
import { relationTypes } from '../../js/constants.js';

export default {
    name: 'RelationTab',
    props: {
        idType: {
            type: Number,
            default: 1,
        },

        /** 当前选中的关系 */
        relation: {
            type: String,
            default: '',
        },

        idTypeAndRelationDisagree: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            items: relationTypes,
            currentIndex: 0
        };
    },

    computed: {
        /**
         * 获取合法的元素列表
         * @return {Array<{ type: string, label: string }>}
         */
        validItems() {
            if (this.idType === 2) {
                return this.items.filter(item => {
                    return item.type !== 'self' && item.type !== 'couple';
                });
            }

            return this.items;
        },
    },

    mounted() {
        if (!this.idTypeAndRelationDisagree) {
            const type = this.validItems[0].type;
            this.$emit('change', type);
        }
    },

    methods: {
        setActive(type) {
            this.$emit('change', type);
        },

        setType(type, index) {
            this.currentIndex = index;
            this.$emit('change', type);
        }
    },
};
</script>

<style scoped>
.item {
    color: #999;
    font-size: 12px;
    border-radius: 4px;
    padding: 8px 12px;
    border: 1px solid #e5e5e5;
    display: inline-block;
    margin-right: 12px;
}

.item.active {
    background: #059eff;
    border-color: #059eff;
    color: #fff;
}
</style>
