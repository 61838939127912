// @ts-check

/** @typedef {Array<{img: string}>} ImgArr */

/**
 * 判断是否身份证
 * @param {{aided_id_type: mmc.review.aided.ID_TYPE}} item
 * @returns {boolean}
 */
function isIdcard(item) {
    return item.aided_id_type === 1;
}

/**
 * 判断是否出生证
 * @param {{aided_id_type: mmc.review.aided.ID_TYPE}} item
 * @returns {boolean}
 */
function isBirthNo(item) {
    return item.aided_id_type === 2;
}

/**
 * 是否包含地址
 * @param {{province: number, city: number}} item
 * @return {boolean}
 */
function hasAddress(item) {
    return item.province > 0 && item.city > 0;
}

/**
 * 是否上传身份证件图片
 * @param {Array<{ img: string }>} item
 * @returns {boolean}
 */
function hasCardImg(item) {
    return item[0].img !== '';
}

/**
 * 返回相应的关系筛选函数
 * @param {string[]} types
 * @returns {(item:any) => boolean}
 */
function includeRelationFilter(...types) {
    return function isRelationOfType(item) {
        console.log(item)
        console.log(item.type, item.payee_term, 11, types)
        return types.indexOf(item.type) !== -1;
    };
}

/**
 * 返回相应的关系筛选函数，排除参数中的所有关系
 * @param {string[]} types
 * @returns {(item:any) => boolean}
 */
function excludeRelationFilter(...types) {
    return function isRelationOfType(item) {
        return types.indexOf(item.type) === -1;
    };
}

/**
 * 生成关系证明图片的判别函数
 *
 * @param {number[]} idxes 这些索引值对应的图片至少有一张必须存在，否则返回 false
 * @returns {(items: Array<{ img: string }>) => boolean}
 */
function hasRelationImg(idxes) {
    return function isRelationImgValid(items) {
        for (let i = 0; i < idxes.length; i++) {
            const idx = idxes[i];
            const item = items[idx] || {};
            if (item.img !== '') {
                return true;
            }
        }

        return false;
    };
}

/**
 * 判断是否包含正确的医院信息
 * @param {string} key
 * @returns { (item: Record<string, string>) => boolean }
 */
function hasHospitalInfo(key) {
    return function(item) {
        return !!item[key];
    };
}

export default [{
        id: 'hospital_info',
        filter: includeRelationFilter('inpatient'),
        rules: [{
                func: hasHospitalInfo('no'),
                msg: '请填写正确的患者住院号',
            },
            {
                func: hasHospitalInfo('class_name'),
                msg: '请填写正确的患者所在科室',
            },
            {
                func: hasHospitalInfo('bed_no'),
                msg: '请输入正确的患者所在床号',
            },
        ],
    },
    {
        id: 'payee_name',
        filter: excludeRelationFilter('inpatient'),
        rules: [
            { required: true, minlength: 2, msg: '请填写正确的收款人真实姓名' },
        ],
    },
    {
        id: 'payee_name',
        filter: includeRelationFilter('inpatient'),
        rules: [{
            required: true,
            minlength: 2,
            msg: '请填写正确的医院对公账户名称',
        }, ],
    },
    {
        id: 'card_no',
        filter: includeRelationFilter('family', 'couple'),
        rules: [{
            required: true,
            msg: '请填写正确的收款人身份证号码',
        }, ],
    },
    {
        id: 'phone',
        filter: excludeRelationFilter('inpatient'),
        rules: [
            { required: true, phone: true, msg: '请输入正确的收款人手机号' },
        ],
    },
    {
        id: 'payee_location_code',
        rules: [{
            required: true,
            msg: '请选择开户银行',
        }, ],
    },
    {
        id: 'payee_account',
        rules: [
            { required: true, minlength: 2, msg: '请填写正确的收款人银行卡号' },
        ],
    },
    {
        id: 'bank_loc',
        rules: [{ func: hasAddress, msg: '请选择开户行所在省市' }],
    },
    {
        id: 'payee_location_branch',
        rules: [{
            required: true,
            minlength: 2,
            msg: '请填写正确的开户支行地址',
        }, ],
    },
    {
        id: 'card_img',
        filter: includeRelationFilter('family', 'couple'),
        rules: [{
            func: hasCardImg,
            msg: '请上传收款人手持身份证图片',
        }, ],
    },
    {
        id: 'relation_img',
        filter: includeRelationFilter('family'),
        rules: [{
                func: hasRelationImg([0, 1, 2]),
                msg: '请上传患者和收款人关系证明图片',
            },
            {
                func: hasRelationImg([0]),
                msg: '请上传患者户口簿首页图片',
            },
            {
                func: hasRelationImg([1]),
                msg: '请上传患者户口簿本人页图片',
            },
            {
                func: hasRelationImg([2]),
                msg: '请上传收款人户口簿本人页图片',
            },
        ],
    },
    {
        id: 'relation_img',
        filter: includeRelationFilter('couple'),
        rules: [{
            func: hasRelationImg([0]),
            msg: '请上传患者和收款人结婚证图片',
        }, ],
    },
];